<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-12">
                <div class="darmin card header-card theme-form">
                    <div class="row justify-content-end">
                        <div class="col-md-8 d-flex align-items-center justify-content-end gap-2">
                            Especialista:
                            <div>
                                <select class="form-select" v-model="especialistaSelected">
                                    <option :value="undefined"></option>
                                    <option v-for="d in doctoresArrayForFilter" :key="d.id" :value="d.id">{{ d.fullName
                                        }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="darmin card">
                    <div class="card-body">
                        <div class="table mb-3">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Paciente</th>
                                        <th>Tipo</th>
                                        <th>Especialista</th>
                                        <th>Hora</th>
                                        <th>Estado</th>
                                        <th class="text-center">...</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="e, i in eventsOrdered" :key="i">
                                        <td>
                                            {{ e.paciente.fullName }}
                                        </td>
                                        <td>
                                            {{ e.motivo.name }}
                                        </td>
                                        <td>
                                            {{ e.especialista.fullName }}
                                        </td>
                                        <td>
                                            {{ DateTime.fromISO(e.timeStart).toFormat('h:mm a') }}
                                        </td>
                                        <td>
                                            <CitaStatus :value="e.status" />
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div class="icon-container d-grid">
                                                    <div class="event__view_btn ms-2 d-flex align-items-center text-primary"
                                                        data-bs-toggle="tooltip" data-bs-title="Default tooltip"
                                                        role="button" @click.open="handleOpenCitaClick(e)">
                                                        <i class="far fa-file-edit"></i>
                                                    </div>
                                                    <div class="event__view_btn ms-2 d-flex align-items-center text-primaryy"
                                                        data-bs-toggle="tooltip" data-bs-title="Default tooltip"
                                                        role="button" @click.open="handleOpenPacienteClick(e)">
                                                        <i class="far fa-user"></i>
                                                    </div>
                                                    <div class="event__view_btn ms-2 d-flex align-items-center text-danger"
                                                        data-bs-toggle="tooltip" data-bs-title="Default tooltip"
                                                        role="button" @click.stop="handleCancelEventClick(e)">
                                                        <i v-if="e.status > 0 && e.status < 3"
                                                            class="fas fa-lg fa-times"></i><i
                                                            v-else>&nbsp;&nbsp;&nbsp;</i>
                                                    </div>
                                                    <div class="event__view_btn ms-2 d-flex align-items-center text-success"
                                                        data-bs-toggle="tooltip" data-bs-title="Default tooltip"
                                                        role="button" @click.stop="handleDowngradeStatus(e)">
                                                        <i v-if="e.status > 1" class="fas fa-chevron-left"></i>
                                                        <i v-else>&nbsp;&nbsp;&nbsp;</i>
                                                    </div>
                                                    <div class="event__view_btn ms-2 d-flex align-items-center text-success"
                                                        data-bs-toggle="tooltip" data-bs-title="Default tooltip"
                                                        role="button" @click.stop="handleUpgradeStatus(e)">
                                                        <i v-if="e.status < 3" class="fas fa-chevron-right"></i>
                                                        <i v-else>&nbsp;&nbsp;&nbsp;</i>
                                                    </div>
                                                    <div class="event__view_btn ms-2 d-flex align-items-center text-info"
                                                        data-bs-toggle="tooltip" data-bs-title="Default tooltip"
                                                        role="button" @click.stop="handleViewConsultaClick(e)"
                                                        v-if="e.control && e.type === 'consulta'">
                                                        <i class="far fa-file"></i>
                                                    </div>
                                                    <div class="event__view_btn ms-2 d-flex align-items-center text-warning"
                                                        data-bs-toggle="tooltip" data-bs-title="Default tooltip"
                                                        role="button" @click.stop="handleNewConsultaClick(e)"
                                                        v-else-if="e.type === 'consulta'">
                                                        <i class="far fa-file"></i>
                                                    </div>
                                                    <div v-else class="event__view_btn ms-2 d-flex align-items-center"
                                                        data-bs-toggle="tooltip" data-bs-title="Default tooltip"
                                                        role="button">&nbsp;&nbsp;&nbsp;</div>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex flex-column align-items-end flex-md-row justify-content-end"
                            style="font-size: 0.7rem">
                            <div class="ms-2">
                                <i class="far fa-user"></i> Ver
                                Paciente
                            </div>
                            <div class="ms-2">
                                <i class="fas fa-lg fa-times text-danger"></i> Cancelar
                                cita
                            </div>
                            <div class="ms-2">
                                <i class="fas fa-chevron-left text-success"></i> Estado Anterior
                            </div>
                            <div class="ms-2">
                                <i class="fas fa-chevron-right text-success"></i> Estado Siguiente
                                cita
                            </div>
                            <div class="ms-2">
                                <i class="far fa-file text-warning"></i>
                                Crear consulta
                            </div>
                            <div class="ms-2">
                                <i class="far fa-file text-info"></i>
                                Ver Historia
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <CitasSummaryCard :events="eventsOrdered" />
            </div>
        </div>
        <ModalConfirm :modal-id="cancelModalId" @onClose="closeModal()" @onConfirm="() => handleCancelEvent()">Va a
            cancelar este evento, esta seguro?
        </ModalConfirm>
        <div v-if="env === 'development'">
            <pre>{{ eventsOrdered }}</pre>
            <pre>{{ especialistaSelected }}</pre>
            <pre>{{ doctoresArrayForFilter }}</pre>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { DateTime } from 'luxon';

import { useVfm } from 'vue-final-modal';
import WindowLayout from '@/layouts/Window.layout.vue';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';

import useSocket from '@/services/useSocket';
import CitaStatus from './components/CitaStatus.vue';
import { useCita } from './services/useCita';
import { useCitas } from '../Agenda/services/useCitas';

import CitasSummaryCard from './components/CitasSummaryCard.vue';

export default {
    name: 'Monitor',
    components: {
        WindowLayout,
        ModalConfirm,
        CitaStatus,
        CitasSummaryCard,
    },
    props: {
        title: { type: String, default: 'Monitor' },
    },
    setup() {
        const store = useStore();
        const citaClicked = ref();
        const cancelModalId = Symbol('calcelModalId');
        const especialistaSelected = ref();
        const { socket } = useSocket();
        // eslint-disable-next-line object-curly-newline
        const { citas: events, getCitas, buildFilters: citasFilter } = useCitas();
        const { cancelCita, upgradeStatus, downgradeStatus, } = useCita();

        const vfm = useVfm();

        const eventsOrdered = computed(() => {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            const es = events.value?.sort((a, b) => DateTime.fromISO(a.timeStart).diff(DateTime.fromISO(b.timeStart)).milliseconds);
            if (especialistaSelected.value) {
                return es.filter((e) => e.especialista.id === especialistaSelected.value);
            }
            return es;
        });

        const doctoresArrayForFilter = computed(() => {
            const docArr = events.value.map((e) => e.especialista);
            const seen = new Set();

            return docArr.filter((e) => {
                if (seen.has(e.id)) {
                    return false;
                }
                seen.add(e.id);
                return true;
            });
        });

        const handleGetCitas = () => {
            const fechaObj = DateTime.now().startOf('day');

            const filters = citasFilter(
                fechaObj,
                // eslint-disable-next-line comma-dangle
                fechaObj.plus({ day: 1 }),
            );
            getCitas(filters);
        };

        onBeforeMount(() => {
            handleGetCitas();
        });

        onMounted(() => {
            socket.on('rte:CareAgendaCitas', async () => {
                handleGetCitas();
            });
        });

        const normarlizeDate = (date) => DateTime.fromISO(date).toFormat('hh:mm a');

        const procedimiento = (event) => {
            if (event.type === 'procedimientos') {
                return event.procedimiento.name;
            }
            return 'Consulta';
        };

        const handleCancelEventClick = (cita) => {
            citaClicked.value = cita;
            vfm.open(cancelModalId);
        };

        const handleCancelEvent = async () => {
            vfm.close(cancelModalId);
            await cancelCita(citaClicked.value.id);
            citaClicked.value = null;
            // handleGetCitas();
        };

        const handleUpgradeStatus = async (cita) => {
            await upgradeStatus(cita.id);
            // handleGetCitas();
        };

        const handleDowngradeStatus = async (cita) => {
            await downgradeStatus(cita.id);
            // handleGetCitas();
        };

        const handleViewConsultaClick = (cita) => {
            if (cita.control) {
                store.dispatch('ContentManager/openWindow', {
                    id: `AsistenteDeConsulta_${cita.control?.cita}`,
                    component: 'AsistenteDeConsulta',
                    name: `Consulta ${cita.consecutive}`,
                    params: {
                        name: 'Consulta',
                        headerTitle: 'Editar Consulta',
                        item: {
                            id: cita.control?.consulta,
                        },
                    },
                });
            }
        };

        const handleNewConsultaClick = (cita) => {
            store.dispatch('ContentManager/openWindow', {
                id: `AsistenteDeConsulta_${Date.now()}`,
                component: 'AsistenteDeConsulta',
                name: 'Nueva Consulta',
                params: {
                    name: 'Consulta',
                    headerTitle: 'Editar Consulta',
                    item: {
                        cita: cita.id,
                        formType: cita.motivo.formType,
                        paciente: { id: cita.paciente.id },
                        doctor: [{ id: cita.especialista.id }],
                    },
                },
            });
        };

        const handleOpenPacienteClick = (cita) => {
            store.dispatch('ContentManager/openWindow', {
                component: 'PacienteForm',
                id: `PacienteForm_${cita.paciente.id}`,
                name: `${cita.paciente.fullName}`,
                params: {
                    name: 'Paciente',
                    headerTitle: 'Editar Paciente',
                    item: cita.paciente,
                },
            });
        };

        const handleOpenCitaClick = (cita) => {
            store.dispatch('ContentManager/openWindow', {
                component: 'CitaForm',
                id: `CitaForm_${cita.id}`,
                name: 'Cita programada',
                params: {
                    name: 'Cita programada',
                    headerTitle: 'Editar Cita',
                    item: { ...cita },
                },
            });
        };

        const closeModal = () => {
            vfm.close(cancelModalId);
        };

        return {
            handleGetCitas,
            procedimiento,
            normarlizeDate,
            especialistaSelected,
            handleCancelEventClick,
            handleCancelEvent,
            handleOpenPacienteClick,
            handleNewConsultaClick,
            handleViewConsultaClick,
            cancelModalId,
            citaClicked,
            closeModal,
            events,
            DateTime,
            eventsOrdered,
            doctoresArrayForFilter,
            handleUpgradeStatus,
            handleDowngradeStatus,
            handleOpenCitaClick,
            env: process.env.NODE_ENV,
        };
    },
};
</script>

<style scoped>
.icon-container {
    grid-template-columns: repeat(6, 1fr);
    max-width: 110px;
}
</style>
